* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-base;
  font-size: $font-size;
  color: $color-primary;
  min-width: 320px;
}

h1 {
  font-size: 44px;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 32px;
  }
}
h2 {
  font-weight: 400;
}
h4 {
  text-transform: uppercase;
  font-weight: 600;
  margin: 30px 0 0 0;
}
h5 {
  // color: $color-accent;
  font-weight: 600;
  font-size: 14px;
}
p {
  line-height: 1.6;
  margin: 20px 0;
}
ul {
  padding-left: 60px;
}
li {
  line-height: 1.6;
}
a {
  font-weight: 600;
  text-decoration: none;
  color: $color-highlight;
}

img {
  width: 100%;
}
label {
  display: block;
  padding: 18px 0 4px 0;
  position: relative;
}
select {
  display: block;
  width: 100%;
}
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password']
{
  font-family: $font-base;
  display: block;
  width: 100%;
  max-width: 450px;
  height: 50px;
  border: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .5);
  padding-left: 10px;
  font-size: 16px;

  &:disabled {
    color: rgba(red($color-primary), green($color-primary), blue($color-primary), .2);
    border: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .2);
    cursor: not-allowed;
  }
}
textarea {
  font-family: $font-base;
  font-size: 16px;
  display: block;
  width: 100%;
  max-width: 450px;
  height: 200px;
  border: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .5);
  padding: 10px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: "Raleway";
  color: $color-primary;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: "Raleway";
  color: $color-primary;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: "Raleway";
  color: $color-primary;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: "Raleway";
  color: $color-primary;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;

  & + label {
    //line-height: 40px;
    line-height: 1.6;
    vertical-align: middle;

    &:before {
      font-family: "icomoon";
      color: rgba(red($color-accent), green($color-accent), blue($color-accent), 0);
      display: inline-block;
      line-height: 20px;
      vertical-align: 0;
      text-align: center;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .5);
      margin-right: 10px;
      transition: color .4s;
      //margin-top: -2px;


    }

    &.floating {
      padding-left: 28px;

      &:before {
        float: left;
        margin-left: -30px;
        margin-top: 1px;
      }
    }
  }

  &:checked + label::before {
    color: rgba(red($color-accent), green($color-accent), blue($color-accent), 1);
  }
  &:disabled + label::before {
    //background-color: $c-light-gray;
  }
}


input[type="checkbox"] {
  & + label::before {
    content: "\ea5e";
    font-size: 18px;

  }
}

input[type="radio"] {
  & + label::before {
    border-radius: 10px;
    content: "\ea98";
    font-size: 12px;
  }
}
table {
  border-collapse: collapse;
  width: 100%;
}
th {
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
  padding-bottom: 20px;
  // border-bottom: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid #e5e5e5;
}
tr {
}
td {
  // border-bottom: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0;
}
