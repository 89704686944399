.custom {
    &--subtitle {
        margin-top: 40px;
    }
    &--gallery {
        margin-top: 30px;
    }

    &--item {
        @include with-layout(12 1/2) {
            @include gallery(3 of 12);
            margin-bottom: gutter();
            
            @media(max-width: 600px) {
                @include gallery(4 of 12);
    
            }
            @media(max-width: 460px) {
                @include gallery(6 of 12);
    
            }

        }

    }
}