.badge {
    position: absolute;
    top: 30px;
    left: -55px;
    width: 200px;
    line-height: 30px;
    text-align: center;
    // padding: 5px 60px;
    transform: rotateZ(-45deg);
    font-weight: 400;
    &-container {
        position: relative;
        overflow: hidden;
    }

    &-unavailable {
      background-color: #eee;
      color: #bbbbbb;
    }
    &-promo {
      background-color: $color-highlight;
      color: white;
    }

    &-small {
        font-size: 12px;
        line-height: 25px;
        top: 30px;
        left: 30px;
        transform: translate(-50%, -50%) rotateZ(-45deg);
        
        width: 200%;
    }
    &-smaller {
        font-size: 12px;
        line-height: 20px;
        top: 22px;
        left: 24px;
        transform: translate(-50%, -50%) rotateZ(-45deg);
        
        width: 200%;
    }
    // opacity: .6;
}