.burger-menu {
  position: absolute;
  top: 50%;
  right: gutter();
  transform: translateY(-50%);
  margin: 0 0 0 20px;
  text-transform: uppercase;
  color: $color-primary;
  opacity: .6;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  display: none;
  font-size: 24px;

  @media (max-width: 800px) {
    display: block;
  }

  i {
    vertical-align: -1px;
  }
}