.filters {
  float: right;
}
.filter {
  color: $color-primary;
  font-weight: 300;
  margin-left: 20px;
  font-size: 16px;

  &:before {
    vertical-align: middle;
    margin-right: 5px;
    display: inline-block;
    content: "";
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  &-all {
    &:before {
      border: 1px solid rgba(0, 0, 0, .4);
      background: white;
    }
  }
  &-new {
    &:before {
      background: $color-accent;
    }
  }

  &-processing {
    &:before {
      background: $color-highlight;
    }
  }

  &-finalized {
    &:before {
      background: $color-primary;
    }
  }
}