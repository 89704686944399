.parsley {
  &-errors-list {
    list-style: none;
    font-style: italic;
    font-weight: 500;
    color: $color-parsley-error;
    padding-left: 0;

    li {
      margin-top: 5px;
      //margin-bottom: -5px;
    }
  }

  &-error {
    -webkit-box-shadow: 0 0 10px 0 $color-parsley-error;
    box-shadow: 0 0 10px 0 $color-parsley-error;
    border-color: $color-parsley-error !important;
  }
  //&-success {
  //  -webkit-box-shadow: 0 0 10px 0 $color-parsley-success;
  //  box-shadow: 0 0 10px 0 $color-parsley-success;
  //  border-color: $color-parsley-success !important;
  //}
}