.alert {
  background-color: #ECEFF1;
  border: 1px solid #B0BEC5;
  color: #78909C;
  padding: 10px 0;
  margin: 20px 0;
  
  &-danger {
    color: white;
    background-color: #FF8A65;
    border: 1px solid #FF5722;
  }
  
  h5 {
    padding-left: 60px;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 5px;
    text-transform: uppercase;
  }
}
