.product {

  @media (max-width: 800px) {
    padding-top: 40px;
    margin-top: 60px;
  }

  &--images {
    @include span(6 of 11);

    @media (max-width: 900px) {
      @include span(5 of 11);
    }
    @media (max-width: 800px) {
      @include span(full);
    }
  }
  &--cover {
    display: block;
    // height: 460px;
    height: 435px;
    width: 100%;
    overflow: hidden;
  
    @media (max-width: 1000px) {
      height: 300px;
    }
    @media (max-width: 800px) {
      height: 400px;
      max-width: 600px;
      margin: 0 auto;
    }
    @media (max-width: 500px) {
      height: 300px;
    }
    @media (max-width: 380px) {
      height: 200px;
    }
    
    
      img {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--other-images {
    margin-top: 20px;
  }
  &--image {
    display: block;
    @include gallery(4 of 12);
    padding-bottom: span(3);
    margin-bottom: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 900px) {
      @include gallery(6 of 12);
    }
    @media (max-width: 800px) {
      @include gallery(3 of 12);
      padding-bottom: span(2);
      margin-bottom: gutter(12);
    }
    @media (max-width: 500px) {
      @include gallery(4 of 12);
      padding-bottom: span(3);

    }
  }
  &--title {
    margin-bottom: 20px;

    span {
      margin-top: 10px;
      display: block;
      font-size: 70%;
      // font-weight: 600;
      // font-weight: 400;
      // text-transform: uppercase;
      text-transform: capitalize;
      color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
      // color: $color-primary;
    }
  }
  &--info {
    @include span(5 of 11 last);
    
    @media (max-width: 900px) {
      @include span(6 of 11 last);
    }
    @media (max-width: 800px) {
      @include span(full);
      margin-top: 20px;
    }
    h4 {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      margin-top: 20px;
    }
  }
  &--code {
    color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  &--price {
    padding: 10px 0;
    border-top: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .2);
    border-bottom: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .2);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 40px;

    &--value {
      font-size: 30px;
    }
  }

  &--description {
    margin-bottom: 30px;
    p {
      overflow: auto;
      margin: 10px 0;
    }
    strong {
      font-weight: 400;
      display: block;
      width: 120px;
      float: left;
    }
    span {
      display: block;
      margin-left: 120px;
    }
  }

  &--config {
    border-top: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .2);
    padding-top: 30px;

  }
  button {
    margin-top: 20px;
  }
  &--variants {
    @include container();
  }

  &--preview {
    position: relative;
    width: 200px;
    height: 200px;

    &--moss {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 200;
    }
    &--frame {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 100;
    }
  }

}

.variant {
  @include gallery(3 of 12);
  display: block;
  text-align: center;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;

  &--image {
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  label {
    padding: 0;
  }
}

.stock-message {
  // position: relative;
  max-width: 450px;
}
// .out-of-stock-message {
//   display: none;
//   // background: rgba(211, 221, 228, 0.8);
//   // position: absolute;
//   // top: 0;
//   // right: 0;
//   // bottom: 0;
//   // left: 0;
// }