.cart-notification {
  position: fixed;
  //width: 200px;
  //height: 50px;
  background: $color-primary;
  z-index: 9000;
  color: white;
  right: 0;
  top: 110px;
  font-weight: 300;
  vertical-align: middle;
  padding: 7px 30px 12px;
  //transform: translateX(100%);
  //transition: all .5s .5s;
  
  @media (max-width: 800px) {
    font-size: 0;
    padding: 10px 20px 12px 20px;
  
  }
  @media (max-width: 1100px) {
    top: 80px;
  }

  &.is-showing {
    transform: translateX(0);
  }
  &--count {
    font-size: 32px;
    vertical-align: baseline;
    padding: 0 10px 0 30px;
    
    @media (max-width: 800px) {
      font-size: 20px;
      padding-left: 20px;
    }
  }

  .icon {
    font-size: 18px;
    
    @media (max-width: 800px) {
      font-size: 14px;
  
    }
  }
}

.cart {
  table {
    margin-top: 40px;
  }

  tr {
    position: relative;

    @media (max-width: 640px) {
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 20px;
      padding-bottom: 20px;
    }

  }

  th {
    @media (max-width: 640px) {
      display: none;
    }
  }
  th:first-child {
    width: 80px;
  }

  th:nth-child(3) {
    padding-left: 40px;
  }

  th:nth-child(4) {
    padding-left: 10px;
  }


  td {

    @media (max-width: 640px) {
      display: inline-block;
      width: 100%;
      border-bottom: none;
      text-align: center;
    }

    &:nth-child(3) {
      padding-left: 40px;

      @media (max-width: 640px) {
        padding-left: 0;
        width: auto;
      }
    }
    &:nth-child(4) {
      padding-left: 10px;

      @media (max-width: 640px) {
        padding-left: 0;
        width: auto;
      }
    }
  }

  tbody {
    @media (max-width: 640px) {
      text-align: center;
    }

    td {

      &:nth-child(3),
      &:nth-child(4) {
        //padding-left: 40px;

        &::before {
          content: "\ea6a";
          font-family: "icomoon";
          display: none;
          font-weight: 500;
          margin: 0 20px;
          color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);

          @media (max-width: 640px) {
            display: inline-block;
          }

        }
      }
      &:nth-child(3) {
        &::before {
          margin-left: 0;
        }
      }
      //&:nth-child(4) {
      //  //padding-left: 10px;
      //
      //  &::before {
      //    content: "\ea6a";
      //    font-family: "icomoon";
      //    display: none;
      //    font-weight: 500;
      //    margin: 0 20px;
      //    color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
      //
      //    @media (max-width: 640px) {
      //      display: inline-block;
      //    }
      //  }
      //
      //}
      &:last-child {

        @media (max-width: 640px) {
          width: auto;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

    }
  }

  tfoot {
    @media (max-width: 640px) {
      text-align: center;
    }
    tr {
      border-bottom: none;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, .1);

      &:first-child,
      &:nth-child(2) {
        border-bottom: 0;

        @media (max-width: 640px) {
          display: none;
        }
      }


      &:nth-child(5) {
        @media (max-width: 640px) {
          display: none;
        }
      }

    //  &:nth-child(4) {
    //    padding-left: 10px;
    //  }
    //  @media (max-width: 640px) {
    //    display: block;
    //  }
    //
    }

  }

  .button-group {
    margin-top: 20px;

    @media (max-width: 640px) {
      text-align: center;
    }

    //form {
    //  @media (max-width: 640px) {
    //    display: block;
    //  }
    //}

    .button {
      @media (max-width: 640px) {
        width: 100%;
      }
    }

  }
}

.cart-item {
  &--cell-content {
    display: inline-block;

    //@media (max-width: 640px) {
    //  max-width: 100px;
    //}
    //@media (max-width: 550px) {
    //  margin-left: 0;
    //}
  }
  &--image {
    margin-right: 40px;
    position: relative;
    width: 80px;
    height: 80px;
    
    @media (max-width: 640px) {
      margin-right: 0;
    }
  }
  &--moss {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &--frame {
    width: 80px;
    height: 80px;
  }

  &--details {

    h4 {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0 0 5px 0;
    }
  }

  &--dimensions {
    font-weight: 300;

    // br {
    //   display: none;

    //   @media (max-width: 850px) {
    //     display: block;
    //   }
    // }
  }
  &--price {
    font-size: 24px;
    font-weight: 500;
    margin: 0;

    .smaller {
      font-size: 16px;
    }
  }
  &--quantity {
    width: 100px !important;
    height: 40px !important;
    display: inline-block !important;
    font-size: 24px !important;

    &-form {
      position: relative;

      label {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        position: absolute;
        padding: 14px 0 0 0;
        right: 10px;
        top: 0;
        text-transform: uppercase;
      }
    }

    & + .parsley-errors-list {
      position: absolute;
      left: 50%;
      top: 100%;
      margin-top: 15px;
      transform: translateX(-50%);
      background-color: $color-parsley-error;
      color: white;
      border-radius: 5px;
      padding: 10px 15px;
      text-align: center;
      min-width: 200px;
      display: none;
      //-webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,.5);
      //box-shadow: 2px 2px 2px 0 rgba(0,0,0,.5);

      &:before {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 10px solid;
        border-color: transparent transparent $color-parsley-error transparent;
        margin: 0;
      }

      &.filled {
        display: block;
      }

      li {
        margin: 0;
      }
    }

  }



  &--delete {
    color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
    background: transparent;
    padding: 3px 0 0 5px;
    margin: 0 !important;
    cursor: pointer;

    &:hover {
      color: $color-highlight;
    }


  }
}