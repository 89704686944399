.row {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: 1000px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &--image {
    @media (max-width: 700px) {
      margin-bottom: 40px;
    }

    img {
      max-width: 600px;
    }
  }
  &--left.row--image {
    @include span(6 of 11);

    @media (max-width: 1220px) {
      @include span(5 of 11);
    }
    @media (max-width: 700px) {
      @include span(full);
    }
  }

  &--right.row--text {
    @include span(5 of 11 last);

    @media (max-width: 1220px) {
      @include span(6 of 11 last);
    }
    @media (max-width: 700px) {
      @include span(full);
    }
  }

  &--right.row--image {
    @include span(6 of 11 last);

    @media (max-width: 1220px) {
      @include span(5 of 11 last);
    }
    @media (max-width: 700px) {
      @include span(full);
    }
  }

  &--left.row--text {
    @include span(5 of 11);

    @media (max-width: 1220px) {
      @include span(6 of 11);
    }
    @media (max-width: 700px) {
      @include span(full);
    }
  }

  h2 {
    font-size: 44px;
    margin-bottom: 40px;

    @media (max-width: 1100px) {
      font-size: 32px;
      margin-bottom: 20px;
    }
    @media (max-width: 900px) {
      font-size: 26px;
      margin-bottom: 15px;
    }
  }

  &--image {
    text-align: center;

    img {
      //outline: 1px solid red;
    }

  }
  .button {
    margin-top: 30px;

    @media (max-width: 900px) {
      margin-top: 20px;
    }
  }
}

.row-about {

  .row--image {
    @media (max-width: 700px) {
      display: none;
    }
  }
  //  @include span(6 of 11);
  //  margin-top: 60px;
  //
  //  @media (max-width: 1220px) {
  //    @include span(5 of 11);
  //  }
  //  @media (max-width: 700px) {
  //    @include span(full);
  //  }
  //
  //  img {
  //    max-width: 600px;
  //
  //    @media (max-width: 700px) {
  //      max-width: 400px;
  //    }
  //  }
  //}
  //.row--text {
  //  @include span(5 of 11 last);
  //  margin-top: 60px;
  //  margin-bottom: 60px;
  //
  //  @media (max-width: 1220px) {
  //    @include span(6 of 11 last);
  //  }
  //  @media (max-width: 700px) {
  //    @include span(full);
  //  }
  //
  //  @media (max-width: 1100px) {
  //    margin-top: 40px;
  //    margin-bottom: 40px;
  //  }
  //}
   .features {
     @include layout(2 0);
     @include container(100%);
     list-style: none;
     margin-top: 15px;
     margin-bottom: -15px;
     padding: 0;

     li {
       margin-top: 15px;
       margin-bottom: 15px;
       @include gallery(1 of 2);

       @media (max-width: 1100px) {
         margin-top: 10px;
         margin-bottom: 10px;
       }
       @media (max-width: 440px) {
         @include gallery(2 of 2);
         float: none;
         padding-left: 20px;
       }
     }

     i {
       display: inline-block;
       font-size: 30px;
       vertical-align: middle;
       margin-right: 20px;

       @media (max-width: 1100px) {
         font-size: 20px;
         margin-right: 10px;

       }
     }
   }

}

.row-products {
  .layout-wrap {
    @include layout(11 1);
  }

  .row--image {
    @include span(6 of 11 last);
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 1220px) {
      @include span(5 of 11 last);
    }
    @media (max-width: 700px) {
      @include span(full);
    }

    img {
      max-width: 600px;

      @media (max-width: 700px) {
        max-width: 400px;
      }
    }
  }
  .row--text {
    @include span(5 of 11);
    margin-top: 40px;
    margin-bottom: 40px;


    @media (max-width: 1220px) {
      @include span(6 of 11);
    }
    @media (max-width: 700px) {
      @include span(full);
    }

  }
}

.row-custom {

  .row--image {
    @include span(6 of 11);
    margin-top: 40px;
    margin-bottom: 35px;

    @media (max-width: 1220px) {
      @include span(5 of 11);
    }
    @media (max-width: 700px) {
      @include span(full);
    }

    img {
      max-width: 600px;

      @media (max-width: 700px) {
        max-width: 400px;
      }
    }
  }
  .row--text {
    @include span(5 of 11 last);
    margin-top: 100px;
    margin-bottom: 60px;

    @media (max-width: 1220px) {
      @include span(6 of 11 last);
    }

    @media (max-width: 1100px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @media (max-width: 700px) {
      @include span(full);
    }
  }

}