.hero-section {
  background-image: url('/img/despre-hero.jpg');
  background-size: cover;
  background-position: center bottom;
  text-align: center;
  height: 400px;
  margin-top: 70px;
  padding-top: 40px;

  @media (max-width: 700px) {
    background: none;
    height: auto;
  }
}

.long-description {
    margin: 0 auto;
    text-align: center;
    max-width: 900px;
}

.moss-color {
  text-align: center;
  font-size: 16px;
  display: inline-block;
  width: 16.6666%;
  // width: 140px;
  margin: 30px 0;
  vertical-align: top;
  padding: 0 5px;
  
  @media (max-width: 1100px) {
    margin: 2.7% 0;

    // margin-bottom: 20px;
  }
  @media (max-width: 770px) {
    width: 25%;
    
    // margin-bottom: 20px;
  }
  @media (max-width: 470px) {
    width: 33.3333%;
  }

  &--image {
    display: inline-block;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    background-position: 50% bottom;
    background-size: 160%;

    @media (max-width: 1100px) {
      width: 80%;
      height: 0;
      padding-bottom: 80%;
    }

  }

  &--gallery {
    text-align: center;
    font-size: 0;
    //@include container();
    margin: 0;
  
    // @media (max-width: 470px) {
    //   text-align: left;
    // }
  }

  &--name {
    margin-top: 10px;
    @media (max-width: 700px) {
      font-size: 14px;
    }
  }
}




.frame {
  @extend .moss-color;

  &--gallery {
    @extend .moss-color--gallery;
  }

  &--image {
    @extend .moss-color--image;

    background-position: center;
    background-size: cover;
  }

  &--name {
    @extend .moss-color--name;
  }
}

.specification {
  @include gallery(5 of 10);
  margin: 20px 0;

  @media (max-width: 700px) {
    @include gallery(10 of 10);
    float: none;
  }

  &--icon {
    display: inline-block;
    font-size: 30px;
    //width: 30px;
    vertical-align: middle;
    margin-right: 20px;
  }
  &--description {
    padding-left: 50px;

    p {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}

.centered-long {
  text-align: center;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 760px) {
    text-align: left;
  }
}