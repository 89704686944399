.social-links {
  font-size: 0;
  text-align: center;
  //border-top: 1px solid rgba(255, 255, 255, .2);
  //border-bottom: 1px solid rgba(255, 255, 255, .2);
  padding-bottom: 20px;

  a {
    font-size: 30px;
    display: inline-block;
    margin: 0 10px;
    color: rgba(255, 255, 255, .9);
  }
}

.contact {
  .social-links {
    padding: 20px 0;
    margin: 40px 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    a {
      font-size: 40px;
      color: $color-primary;
    }
  }

}

