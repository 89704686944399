.header {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  // padding: 15px 0;
  z-index: 8000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10000;
  
  @media (max-width: 1100px) {
    // padding: 10px 0;
  }

  &--logo {
    margin: 15px 0;
    display: inline-block;
    @media (max-width: 1100px) {
      margin: 10px 0;
    }

    img {
      width: 120px;

      // @media (max-width: 1100px) {
      //   width: 120px;
      // }
    }
  }

  .layout-wrap {
    position: relative;
  }

  &--menu {
    list-style: none;
    font-size: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: gutter();
    margin-right: -20px;
    // transform: translateY(-50%);

    @media (max-width: 800px) {
      display: none;
    }
    i {
      font-size: 12px;
      vertical-align: 1px;
    }

    li {
      display: inline-block;
      height: 100%;
      font-size: 16px;
      // @media (max-width: 1100px) {
      //   font-size: 16px;
      // }
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
      //opacity: .6;
      height: 100%;
      font-weight: 500;
      padding: 25px 20px 0;
      display: inline-block;
      // outline: 1px solid red;

      @media (max-width: 1100px) {
        padding: 20px 15px 0;
      
        // margin: 0 10px;
      }
      //transition: opacity .3s;

      &:hover {
        color: rgba(red($color-primary), green($color-primary), blue($color-primary), 1);

      }
    }
  }
}
.menu {
  &--orders {
    &-new {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      background-color: $color-accent;
      //vertical-align: -4px;
      text-align: center;
      margin-left: 5px;
      color: white;
    }
    &-processing {
      @extend .menu--orders-new;
      background-color: $color-highlight;

    }
  }
}


.dropdown {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
  background-color: white;
  z-index: 9000;
  overflow: auto;
  padding: 40px 0;
  transform: translateY(-100%) translateZ(0);
  visibility: hidden;
  transition: visibility .3s, transform .3s;
  
  &.is-visible {
    visibility: visible;
    transform: translateY(0) translateZ(0);
  }
  
  // @media (max-width: 1100px) {
  //   top: 60px;
  // }
  @media (max-width: 800px) {
    display: none;
  }
    
  h3 {
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 20px;
  }
  p {
    margin: 20px 0 0 0;
    line-height: 1.8;
  }
  a {
    display: block;
    line-height: 1.8;
    font-weight: 400;
    font-size: 14px;
    color: $color-primary;
    position: relative;
    transition: font-weight .3s;
    
    &:hover {
      font-weight: 600;
    }
    
    &::before {
      content: '\e992';
      font-family: 'Icomoon';
      font-size: 12px;
      position: absolute;
      left: -15px;
      top: 3px;
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity .3s, transform .3s;
    }
    &:hover::before {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .layout-wrap {
    position: relative;
    @include container(10 1/4 1380px);
  }
  // &--description {
  //   @include span(4 of 11);
    
  //   @media (max-width: 1140px) {
  //     display: none;
  //   }
  // }
  &--categories {
    @include gallery(2 of 10);
  }
  // &--boxes {
  //   margin-left: span(1) + gutter();
  //   @include span(2 of 11 );
  
  //   @media (max-width: 1140px) {
  //     margin-left: span(2);
  //     @include span(3 of 11 );
  //   }
  // }
  // &--frames {
  //   @include span(2 of 11);
    
  //   @media (max-width: 1140px) {
  //     @include span(3 of 11 );
  //   }
  // }
  // &--other {
  //   @include span(2 of 11 last);
    
  //   @media (max-width: 1140px) {
  //     @include span(3 of 11 );
  //   }
  // }
  
  // &--all {
  //   //position: absolute !important;
  //   //right: 4.7619047619%;
  //   //bottom: 0;
  //   //color: $color-highlight !important;
  //   margin-top: 56px;
  //   //@include span(2 of 11 last)
  // }
}