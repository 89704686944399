.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 20000;
  visibility: hidden;
  transform: translate3d(0, -100%, 0);
  transition: visibility 0s .3s, transform .3s;
  

  &.visible {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: visibility 0s, transform .3s;
  }

  &--header {
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
  }
  &--close {
    font-size: 24px;
  }

  &--items {
    padding-left: 40px;
    list-style: none;
    margin-top: 20px;

    a {
      display: block;
      color: $color-primary;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 18px;
      padding: 10px 0;
    }
  }
}