.all-products {
  text-align: center;

  & > h2 {
    font-size: 32px;
    margin-top: 30px;
  }
}
.product-gallery {
  margin-top: 30px;
}

.gallery-item {
  @include gallery(3 of 12);
  text-align: center;
  color: $color-primary;
  margin: 20px 0;

  @media (max-width: 1150px) {
    @include gallery(4 of 12);
  }
  @media (max-width: 820px) {
    @include gallery(6 of 12);
  }
  @media (max-width: 530px) {
    @include gallery(12 of 12);
    float: none;
  }

  &--image {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    margin-bottom: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
  }

  


  &--title {
    font-size: 26px;
  }

  &--price {
    font-size: 18px;
    font-weight: 300;
    opacity: .8;
    margin-top: 5px;
  }
}

.product-slider {
  margin: 20px 0 0 0;

  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 100;
    padding: 10px;
    // background: transparent;
    // color: $color-primary;
    // font-size: 28px;
  }
  .slick-prev {
    left: 0;
    transform: translateY(-50%) translateX(-50%);
  }
  .slick-next {
    right: 0;
    transform: translateY(-50%) translateX(50%);
  }
}
.slider-item {
  display: block;
  text-align: center;
  color: $color-primary;
  margin: 20px 0;
  padding: 0 30px;

  &--image {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    margin-bottom: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--title {
    font-size: 26px;
  }

  &--price {
    font-size: 18px;
    font-weight: 300;
    opacity: .8;
    margin-top: 5px;
  }
}