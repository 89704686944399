.select-field {
  //max-width: 450px;
  font-size: 0;
}
.selectize {
  &-control {
    max-width: 450px;
  }

  &-input {
    border-radius: 0;
    border: 1px solid rgba(red($color-primary), green($color-primary), blue($color-primary), .5);
    box-shadow: none;
    padding: 15px 0 15px 10px;
    height: 50px;

    input {
      height: auto;

    }
  }
  &-dropdown {
    font-size: 14px;
    box-shadow: 0 1px 15px 5px rgba(0, 0, 0, 0.1);
  }

}
.selectize-control.single .selectize-input.input-active {
  box-shadow: none;
}
.selectize-control.single .selectize-input.dropdown-active {
  box-shadow: none;
}
.selectize-control.single .selectize-input:after {
  border-top-color: $color-accent;
  border-width: 8px 8px 0 8px;
}
.selectize-control.single .selectize-input.dropdown-active:after {
  border-width: 0 8px 8px 8px;
  border-bottom-color: $color-accent;
}
.selectize-dropdown [data-selectable] {
  padding: 10px 0 10px 0;
}
.selectize-dropdown .optgroup-header {
  line-height: 40px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(90, 100, 110, 0.39);
  border-top: 1px solid #e5e5e5;
  padding: 0 0 0 15px;
  background: #f8f8f8;
  // font-style: italic;
}
.selectize-dropdown .optgroup .option {
  padding-left: 40px;
}

.select--images {
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}
.select--text {
  //text-shadow: 1px 1px 0 rgba(255, 255, 255, .7);
  display: inline-block;
  margin-top: 1px;
  padding: 5px 15px 5px 20px;
  background: rgba(255, 255, 255, .6);
}
.frame-selected {
  display: block;
  margin: -15px 0 -15px -10px;
  padding: 15px 0 15px 0;
  background-repeat: no-repeat;
  background-size: cover;

  &--text {
    padding: 5px 15px 5px 10px;
    background: rgba(255, 255, 255, .6);
    font-size: 14px;
    //font-weight: 400;
  }
}