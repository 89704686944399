.slider {
  background-image: url("/img/slider.jpg");
  background-size: cover;
  background-position: center bottom ;
  width: 100%;
  height: 600px;
  margin-top: 60px;
  overflow: auto;
  
  h1 {
    text-align: right;
    font-size: 36px;
    margin-top: 110px;
    
    @media (max-width: 1100px) {
      font-size: 32px;
    }
    @media (max-width: 900px) {
      font-size: 26px;
    }
    @media (max-width: 650px) {
      //font-size: 26px;
      text-align: center;
      background-color: rgba(255, 255, 255, .85);
      margin-top: 95px;
      margin-left: -5%;
      margin-right: -5%;
      padding: 15px 0;
    }
    small {
      display: block;
      margin-top: 10px;
    }
  }

  @media (max-width: 1400px) {
    height: 400px;
  
  }
  @media (max-width: 950px) {
    background-position: left -50px bottom 0;
  }
  @media (max-width: 850px) {
    background-position: left -100px bottom 0;
  }
  @media (max-width: 800px) {
    background-position: left -200px bottom 0;
  }
}