.status {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;

  &-new {
    background: $color-accent;
  }
  &-processing {
    background: $color-highlight;
  }
  &-finished {
    background: $color-primary;
  }
  &-disabled {
    background: $color-primary;
    opacity: .3;
  }
  &--form {
    display: inline-block;
    width: 200px;
    vertical-align: middle;
    margin-left: 10px;
  }
  &-select {
    height: 40px;
    .selectize {
      &-input {
        height: 40px;
        padding: 10px 0 10px 10px;
        //margin: 5px 0 5px 10px;

      }
    }
    &.selectize-control.single .selectize-input:after {
      border-top-color: white;
      border-width: 8px 8px 0 8px;
    }
    &.selectize-control.single .selectize-input.dropdown-active:after {
      border-width: 0 8px 8px 8px;
      border-bottom-color: white;
    }
  }
  &--selected {
    display: block;
    margin: -10px 0 -10px -10px;
    padding: 10px 0 10px 10px;
    color: white;
    font-weight: 500;
    font-size: 16px;

  }
  &--colors {
    color: white !important;
    font-weight: 400;
    font-size: 16px;
    padding-left: 10px !important;

    &:hover {
      color: white;
      font-weight: 600;
    }
  }
}
.order {

  &--table {
    margin-top: 40px;
    margin-bottom: 40px;

    p {
      margin: 0;
    }

    td,th {
     padding-left: 3px;
     padding-right: 3px;
    }
    td {
      border: none;
    }
  }
  &--multiple-items {
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    & + .order--single-item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }


  }
  &--link {
    color: $color-primary;
    font-weight: 400;
    display: block;
  }
  &--new {
    //background-color: rgba(0, 0, 0, .05);
  }
  &--finished {
    opacity: .6;
    &:hover {
      //opacity: 1;
    }
  }

  &--client-name {
    text-transform: uppercase;
    font-weight: 600;
  }
  &--client-email {
    font-style: italic;
  }
  &--client-phone {
    font-style: italic;
  }

  &--variant {
    &-images {
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;      
      position: relative;
    }
    &-moss {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &-frame {

    }
  }
  &--product-details {
    display: inline-block;
    vertical-align: middle;
    //font-weight: 300;
  }
  &--product-name {
    font-weight: 600;
    text-transform: uppercase;
  }

  &--numbers {
    font-size: 24px;
    font-weight: 600;

    .icon {
      font-size: 16px;
      margin-right: 10px;
      color: rgba(red($color-primary), green($color-primary), blue($color-primary), .5);
    }
    .smaller {
      font-size: 16px;
      font-weight: 400;
    }
  }


  &--delete {
    padding: 0;
    background: transparent;
    color: rgba(red($color-primary), green($color-primary), blue($color-primary), .6);
  }


  &--info {
    @include span(5 of 11);
  }

  &--products {
    @include span(6 of 11 last);

    tfoot {
      tr {
        border-bottom: none;

        &:first-child {
          td {
            border: none;
            padding-bottom: 0;
          }
        }
        &:nth-child(2) {
           td {
             padding-top: 0;
             border: none;
             padding-bottom: 0;
           }
         }
        &:nth-child(3) {
          td {
            padding-top: 0;
            //border: none;
            //padding-bottom: 0;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }

      }
      td {
        border-bottom: 1px solid rgba(0, 0, 0, .1);

        &:first-child,
        &:nth-child(2) {
          border-bottom: 0;
        }
      }
    }
  }

  &--date {
    float: right;
    font-size: 18px;
    margin-top: 23px;
  }
  &--row {
    @include container();
    margin: 10px 0;
  }
  &--field {
    @include span(4 of 10);
  }
  &--data {
    @include span(6 of 10 last);
    font-weight: 600;
  }

  &--products {
    td, th {
      padding-left: 3px;
      padding-right: 3px;
    }
    th {
      padding-top: 18px;
      padding-bottom: 4px;
    }

  }

  &--action-buttons {
      display: inline-block;
      /*font-size: 20px;*/

    .button {
      display: inline-block;
      padding: 0 20px;
      vertical-align: 4px;
    }
  }

  &-item {
    &--cell-content {

    }
    &--images {
      font-size: 0;
      position: relative;
      width: 50px;
      height: 50px;

      .icon {
        font-size: 16px;
        color: $color-primary;
        opacity: .4;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
      }
    }
    &--moss {
      // vertical-align: middle;
      width: 50px;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      // display: inline-block;

    }
    &--frame {
      vertical-align: middle;
      width: 50px;
      // height: 36px;
      // border-radius: 50%;
      display: inline-block;
    }
    &--name {
      margin: 0;
    }

  }
}
