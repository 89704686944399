.notification {
  width: 100%;
  color: white;
  padding: 10px 15px;
  margin: 10px 0;
  
  p {
    margin: 0;
  }
  
  &--success {
    background-color: $color-accent;
  }
}