.pagination {
  list-style: none;
  font-size: 0;
  text-align: center;
  margin-top: 20px;

  .disabled {
    visibility: hidden;
  }

  li {
    display: inline-block;
    font-size: 24px;
    padding: 5px 10px;
  }

  a {
  }
}