.layout-wrap {
  @include container(1380px);
  padding: 0 gutter();
}
.content {
  padding-top: 130px;
  padding-bottom: 80px;
  min-height: 800px;
  @media (max-width: 1100px) {
    padding-top: 100px;
  }
}

.layout-row {
 @include container();
}

.layout-left-half {
  //@include span(6 of 11);
}

.layout-right-half {
  //@include span(5 of 11 last);
}
.text-right {
  text-align: right;
  //float: right;
}
.span-4 {
  @include span(4 of 11);
}
.span-7-last {
  @include span(7 of 11 last);
}