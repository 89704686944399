* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #5a646e;
  min-width: 320px;
}

h1 {
  font-size: 44px;
  font-weight: 400;
}

@media (max-width: 600px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  font-weight: 400;
}

h4 {
  text-transform: uppercase;
  font-weight: 600;
  margin: 30px 0 0 0;
}

h5 {
  font-weight: 600;
  font-size: 14px;
}

p {
  line-height: 1.6;
  margin: 20px 0;
}

ul {
  padding-left: 60px;
}

li {
  line-height: 1.6;
}

a {
  font-weight: 600;
  text-decoration: none;
  color: #ffa500;
}

img {
  width: 100%;
}

label {
  display: block;
  padding: 18px 0 4px 0;
  position: relative;
}

select {
  display: block;
  width: 100%;
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  font-family: "Raleway", sans-serif;
  display: block;
  width: 100%;
  max-width: 450px;
  height: 50px;
  border: 1px solid rgba(90, 100, 110, 0.5);
  padding-left: 10px;
  font-size: 16px;
}

input[type='text']:disabled,
input[type='number']:disabled,
input[type='email']:disabled,
input[type='password']:disabled {
  color: rgba(90, 100, 110, 0.2);
  border: 1px solid rgba(90, 100, 110, 0.2);
  cursor: not-allowed;
}

textarea {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  display: block;
  width: 100%;
  max-width: 450px;
  height: 200px;
  border: 1px solid rgba(90, 100, 110, 0.5);
  padding: 10px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Raleway";
  color: #5a646e;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Raleway";
  color: #5a646e;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Raleway";
  color: #5a646e;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Raleway";
  color: #5a646e;
  opacity: .5;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  line-height: 1.6;
  vertical-align: middle;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  font-family: "icomoon";
  color: rgba(127, 169, 36, 0);
  display: inline-block;
  line-height: 20px;
  vertical-align: 0;
  text-align: center;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(90, 100, 110, 0.5);
  margin-right: 10px;
  transition: color .4s;
}

input[type="checkbox"] + label.floating,
input[type="radio"] + label.floating {
  padding-left: 28px;
}

input[type="checkbox"] + label.floating:before,
input[type="radio"] + label.floating:before {
  float: left;
  margin-left: -30px;
  margin-top: 1px;
}

input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  color: #7fa924;
}

input[type="checkbox"] + label::before {
  content: "\ea5e";
  font-size: 18px;
}

input[type="radio"] + label::before {
  border-radius: 10px;
  content: "\ea98";
  font-size: 12px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  color: rgba(90, 100, 110, 0.6);
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

td {
  border-bottom: 1px solid #e5e5e5;
  padding: 15px 0;
}

.layout-wrap {
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 4.7619047619%;
}

.layout-wrap:after {
  content: " ";
  display: block;
  clear: both;
}

.content {
  padding-top: 130px;
  padding-bottom: 80px;
  min-height: 800px;
}

@media (max-width: 1100px) {
  .content {
    padding-top: 100px;
  }
}

.layout-row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.layout-row:after {
  content: " ";
  display: block;
  clear: both;
}

.text-right {
  text-align: right;
}

.span-4 {
  width: 33.3333333333%;
  float: left;
  margin-right: 4.7619047619%;
}

.span-7-last {
  width: 61.9047619048%;
  float: right;
  margin-right: 0;
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  display: none;
  z-index: 10500;
}

.sl-wrapper {
  z-index: 10400;
}

.sl-wrapper button {
  border: 0 none;
  background: transparent;
  font-size: 28px;
  padding: 0;
  cursor: pointer;
}

.sl-wrapper button:hover {
  opacity: 0.7;
}

.sl-wrapper .sl-close {
  display: none;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10600;
  margin-top: -14px;
  margin-right: -14px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-family: Arial, Baskerville, monospace;
  color: #5a646e;
  font-size: 3rem;
}

.sl-wrapper .sl-close:focus {
  outline: none;
}

.sl-wrapper .sl-counter {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10600;
  color: #5a646e;
  font-size: 1rem;
}

.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}

.sl-wrapper .sl-navigation button {
  position: fixed;
  top: 50%;
  margin-top: -22px;
  height: 44px;
  width: 22px;
  line-height: 44px;
  text-align: center;
  display: block;
  z-index: 10600;
  font-family: Arial, Baskerville, monospace;
  color: #5a646e;
}

.sl-wrapper .sl-navigation button.sl-next {
  right: 5px;
  font-size: 2rem;
}

.sl-wrapper .sl-navigation button.sl-prev {
  left: 5px;
  font-size: 2rem;
}

.sl-wrapper .sl-navigation button:focus {
  outline: none;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 10px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 10px;
    font-size: 3rem;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }
  .sl-wrapper .sl-navigation button.sl-next {
    right: 20px;
    font-size: 3rem;
  }
  .sl-wrapper .sl-navigation button.sl-prev {
    left: 20px;
    font-size: 3rem;
  }
}

.sl-wrapper .sl-image {
  position: fixed;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 20000;
}

.sl-wrapper .sl-image img {
  margin: 0;
  padding: 0;
  display: block;
  border: 0 none;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}

.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0 none;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}

.sl-wrapper .sl-image .sl-caption {
  display: none;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-top {
  bottom: auto;
  top: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}

.sl-wrapper .sl-image .sl-download {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #5a646e;
  z-index: 10600;
}

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 10070;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.header {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 8000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10000;
}

.header--logo {
  margin: 15px 0;
  display: inline-block;
}

@media (max-width: 1100px) {
  .header--logo {
    margin: 10px 0;
  }
}

.header--logo img {
  width: 120px;
}

.header .layout-wrap {
  position: relative;
}

.header--menu {
  list-style: none;
  font-size: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4.7619047619%;
  margin-right: -20px;
}

@media (max-width: 800px) {
  .header--menu {
    display: none;
  }
}

.header--menu i {
  font-size: 12px;
  vertical-align: 1px;
}

.header--menu li {
  display: inline-block;
  height: 100%;
  font-size: 16px;
}

.header--menu a {
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(90, 100, 110, 0.6);
  height: 100%;
  font-weight: 500;
  padding: 25px 20px 0;
  display: inline-block;
}

@media (max-width: 1100px) {
  .header--menu a {
    padding: 20px 15px 0;
  }
}

.header--menu a:hover {
  color: #5a646e;
}

.menu--orders-new, .menu--orders-processing {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: #7fa924;
  text-align: center;
  margin-left: 5px;
  color: white;
}

.menu--orders-processing {
  background-color: #ffa500;
}

.dropdown {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 9000;
  overflow: auto;
  padding: 40px 0;
  transform: translateY(-100%) translateZ(0);
  visibility: hidden;
  transition: visibility .3s, transform .3s;
}

.dropdown.is-visible {
  visibility: visible;
  transform: translateY(0) translateZ(0);
}

@media (max-width: 800px) {
  .dropdown {
    display: none;
  }
}

.dropdown h3 {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.dropdown p {
  margin: 20px 0 0 0;
  line-height: 1.8;
}

.dropdown a {
  display: block;
  line-height: 1.8;
  font-weight: 400;
  font-size: 14px;
  color: #5a646e;
  position: relative;
  transition: font-weight .3s;
}

.dropdown a:hover {
  font-weight: 600;
}

.dropdown a::before {
  content: '\e992';
  font-family: 'Icomoon';
  font-size: 12px;
  position: absolute;
  left: -15px;
  top: 3px;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity .3s, transform .3s;
}

.dropdown a:hover::before {
  transform: translateX(0);
  opacity: 1;
}

.dropdown .layout-wrap {
  position: relative;
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
}

.dropdown .layout-wrap:after {
  content: " ";
  display: block;
  clear: both;
}

.dropdown--categories {
  width: 15.7894736842%;
  float: left;
}

.dropdown--categories:nth-child(5n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.dropdown--categories:nth-child(5n + 2) {
  margin-left: 21.0526315789%;
  margin-right: -100%;
  clear: none;
}

.dropdown--categories:nth-child(5n + 3) {
  margin-left: 42.1052631579%;
  margin-right: -100%;
  clear: none;
}

.dropdown--categories:nth-child(5n + 4) {
  margin-left: 63.1578947368%;
  margin-right: -100%;
  clear: none;
}

.dropdown--categories:nth-child(5n + 5) {
  margin-left: 84.2105263158%;
  margin-right: -100%;
  clear: none;
}

.footer {
  background-color: #3f454c;
  padding: 30px 0 30px 0;
  color: white;
}

.footer h5 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  opacity: .7;
  margin-bottom: 14px;
}

.footer--contact {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  line-height: 1.8;
}

.footer--logo {
  text-align: center;
  margin: 50px 0 30px;
}

.footer--logo img {
  max-width: 200px;
  opacity: .5;
}

.footer--utils {
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.footer--utils h5 {
  margin: -10px 0 5px;
}

.footer--utils a {
  margin: 0 10px;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  padding-bottom: 2px;
}

.footer--utils a:hover {
  border-bottom-color: white;
}

.footer--copyright {
  font-weight: 300;
  opacity: .7;
  text-align: center;
  font-size: 14px;
}

.footer--copyright p {
  margin: 0;
}

.slider {
  background-image: url("/img/slider.jpg");
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 600px;
  margin-top: 60px;
  overflow: auto;
}

.slider h1 {
  text-align: right;
  font-size: 36px;
  margin-top: 110px;
}

@media (max-width: 1100px) {
  .slider h1 {
    font-size: 32px;
  }
}

@media (max-width: 900px) {
  .slider h1 {
    font-size: 26px;
  }
}

@media (max-width: 650px) {
  .slider h1 {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.85);
    margin-top: 95px;
    margin-left: -5%;
    margin-right: -5%;
    padding: 15px 0;
  }
}

.slider h1 small {
  display: block;
  margin-top: 10px;
}

@media (max-width: 1400px) {
  .slider {
    height: 400px;
  }
}

@media (max-width: 950px) {
  .slider {
    background-position: left -50px bottom 0;
  }
}

@media (max-width: 850px) {
  .slider {
    background-position: left -100px bottom 0;
  }
}

@media (max-width: 800px) {
  .slider {
    background-position: left -200px bottom 0;
  }
}

.button, button, .button-secondary, .button-back {
  display: inline-block;
  background-color: #5a646e;
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 50px;
  vertical-align: middle;
  padding: 0 30px;
  font-size: 16px;
  cursor: pointer;
}

button:disabled {
  opacity: .3;
  cursor: not-allowed;
}

.button-secondary {
  color: #8d97a1;
  background: transparent;
}

.button-back {
  color: #8d97a1;
  background: #eeeeee;
}

.button-group form {
  display: inline-block;
}

.form-button {
  display: inline-block;
}

.action-button {
  opacity: .6;
  padding: 0 10px;
  color: #5a646e;
  background: none;
}

.loading-icon {
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
}

@keyframes loading {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(-360deg);
  }
}

.row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 1000px) {
  .row {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 700px) {
  .row--image {
    margin-bottom: 40px;
  }
}

.row--image img {
  max-width: 600px;
}

.row--left.row--image {
  width: 52.380952381%;
  float: left;
  margin-right: 4.7619047619%;
}

@media (max-width: 1220px) {
  .row--left.row--image {
    width: 42.8571428571%;
    float: left;
    margin-right: 4.7619047619%;
  }
}

@media (max-width: 700px) {
  .row--left.row--image {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row--right.row--text {
  width: 42.8571428571%;
  float: right;
  margin-right: 0;
}

@media (max-width: 1220px) {
  .row--right.row--text {
    width: 52.380952381%;
    float: right;
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .row--right.row--text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row--right.row--image {
  width: 52.380952381%;
  float: right;
  margin-right: 0;
}

@media (max-width: 1220px) {
  .row--right.row--image {
    width: 42.8571428571%;
    float: right;
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .row--right.row--image {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row--left.row--text {
  width: 42.8571428571%;
  float: left;
  margin-right: 4.7619047619%;
}

@media (max-width: 1220px) {
  .row--left.row--text {
    width: 52.380952381%;
    float: left;
    margin-right: 4.7619047619%;
  }
}

@media (max-width: 700px) {
  .row--left.row--text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row h2 {
  font-size: 44px;
  margin-bottom: 40px;
}

@media (max-width: 1100px) {
  .row h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

@media (max-width: 900px) {
  .row h2 {
    font-size: 26px;
    margin-bottom: 15px;
  }
}

.row--image {
  text-align: center;
}

.row .button, .row button, .row .button-secondary, .row .button-back {
  margin-top: 30px;
}

@media (max-width: 900px) {
  .row .button, .row button, .row .button-secondary, .row .button-back {
    margin-top: 20px;
  }
}

@media (max-width: 700px) {
  .row-about .row--image {
    display: none;
  }
}

.row-about .features {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  margin-top: 15px;
  margin-bottom: -15px;
  padding: 0;
}

.row-about .features:after {
  content: " ";
  display: block;
  clear: both;
}

.row-about .features li {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 50%;
  float: left;
}

.row-about .features li:nth-child(2n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.row-about .features li:nth-child(2n + 2) {
  margin-left: 50%;
  margin-right: -100%;
  clear: none;
}

@media (max-width: 1100px) {
  .row-about .features li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 440px) {
  .row-about .features li {
    width: 100%;
    float: left;
    float: none;
    padding-left: 20px;
  }
  .row-about .features li:nth-child(1n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: none;
  }
}

.row-about .features i {
  display: inline-block;
  font-size: 30px;
  vertical-align: middle;
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .row-about .features i {
    font-size: 20px;
    margin-right: 10px;
  }
}

.row-products .row--image {
  width: 52.380952381%;
  float: right;
  margin-right: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 1220px) {
  .row-products .row--image {
    width: 42.8571428571%;
    float: right;
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .row-products .row--image {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row-products .row--image img {
  max-width: 600px;
}

@media (max-width: 700px) {
  .row-products .row--image img {
    max-width: 400px;
  }
}

.row-products .row--text {
  width: 42.8571428571%;
  float: left;
  margin-right: 4.7619047619%;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 1220px) {
  .row-products .row--text {
    width: 52.380952381%;
    float: left;
    margin-right: 4.7619047619%;
  }
}

@media (max-width: 700px) {
  .row-products .row--text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row-custom .row--image {
  width: 52.380952381%;
  float: left;
  margin-right: 4.7619047619%;
  margin-top: 40px;
  margin-bottom: 35px;
}

@media (max-width: 1220px) {
  .row-custom .row--image {
    width: 42.8571428571%;
    float: left;
    margin-right: 4.7619047619%;
  }
}

@media (max-width: 700px) {
  .row-custom .row--image {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.row-custom .row--image img {
  max-width: 600px;
}

@media (max-width: 700px) {
  .row-custom .row--image img {
    max-width: 400px;
  }
}

.row-custom .row--text {
  width: 42.8571428571%;
  float: right;
  margin-right: 0;
  margin-top: 100px;
  margin-bottom: 60px;
}

@media (max-width: 1220px) {
  .row-custom .row--text {
    width: 52.380952381%;
    float: right;
    margin-right: 0;
  }
}

@media (max-width: 1100px) {
  .row-custom .row--text {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (max-width: 700px) {
  .row-custom .row--text {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.burger-menu {
  position: absolute;
  top: 50%;
  right: 4.7619047619%;
  transform: translateY(-50%);
  margin: 0 0 0 20px;
  text-transform: uppercase;
  color: #5a646e;
  opacity: .6;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  display: none;
  font-size: 24px;
}

@media (max-width: 800px) {
  .burger-menu {
    display: block;
  }
}

.burger-menu i {
  vertical-align: -1px;
}

.all-products {
  text-align: center;
}

.all-products > h2 {
  font-size: 32px;
  margin-top: 30px;
}

.product-gallery {
  margin-top: 30px;
}

.gallery-item {
  width: 21.7391304348%;
  float: left;
  text-align: center;
  color: #5a646e;
  margin: 20px 0;
}

.gallery-item:nth-child(4n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.gallery-item:nth-child(4n + 2) {
  margin-left: 26.0869565217%;
  margin-right: -100%;
  clear: none;
}

.gallery-item:nth-child(4n + 3) {
  margin-left: 52.1739130435%;
  margin-right: -100%;
  clear: none;
}

.gallery-item:nth-child(4n + 4) {
  margin-left: 78.2608695652%;
  margin-right: -100%;
  clear: none;
}

@media (max-width: 1150px) {
  .gallery-item {
    width: 30.4347826087%;
    float: left;
  }
  .gallery-item:nth-child(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .gallery-item:nth-child(3n + 2) {
    margin-left: 34.7826086957%;
    margin-right: -100%;
    clear: none;
  }
  .gallery-item:nth-child(3n + 3) {
    margin-left: 69.5652173913%;
    margin-right: -100%;
    clear: none;
  }
}

@media (max-width: 820px) {
  .gallery-item {
    width: 47.8260869565%;
    float: left;
  }
  .gallery-item:nth-child(2n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .gallery-item:nth-child(2n + 2) {
    margin-left: 52.1739130435%;
    margin-right: -100%;
    clear: none;
  }
}

@media (max-width: 530px) {
  .gallery-item {
    width: 100%;
    float: left;
    float: none;
  }
  .gallery-item:nth-child(1n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: none;
  }
}

.gallery-item--image {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  margin-bottom: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.gallery-item--title {
  font-size: 26px;
}

.gallery-item--price {
  font-size: 18px;
  font-weight: 300;
  opacity: .8;
  margin-top: 5px;
}

.product-slider {
  margin: 20px 0 0 0;
}

.product-slider .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 100;
  padding: 10px;
}

.product-slider .slick-prev {
  left: 0;
  transform: translateY(-50%) translateX(-50%);
}

.product-slider .slick-next {
  right: 0;
  transform: translateY(-50%) translateX(50%);
}

.slider-item {
  display: block;
  text-align: center;
  color: #5a646e;
  margin: 20px 0;
  padding: 0 30px;
}

.slider-item--image {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  margin-bottom: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.slider-item--title {
  font-size: 26px;
}

.slider-item--price {
  font-size: 18px;
  font-weight: 300;
  opacity: .8;
  margin-top: 5px;
}

@media (max-width: 800px) {
  .product {
    padding-top: 40px;
    margin-top: 60px;
  }
}

.product--images {
  width: 52.380952381%;
  float: left;
  margin-right: 4.7619047619%;
}

@media (max-width: 900px) {
  .product--images {
    width: 42.8571428571%;
    float: left;
    margin-right: 4.7619047619%;
  }
}

@media (max-width: 800px) {
  .product--images {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.product--cover {
  display: block;
  height: 435px;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .product--cover {
    height: 300px;
  }
}

@media (max-width: 800px) {
  .product--cover {
    height: 400px;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .product--cover {
    height: 300px;
  }
}

@media (max-width: 380px) {
  .product--cover {
    height: 200px;
  }
}

.product--cover img {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.product--other-images {
  margin-top: 20px;
}

.product--image {
  display: block;
  width: 30.4347826087%;
  float: left;
  padding-bottom: 23.8095238095%;
  margin-bottom: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.product--image:nth-child(3n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.product--image:nth-child(3n + 2) {
  margin-left: 34.7826086957%;
  margin-right: -100%;
  clear: none;
}

.product--image:nth-child(3n + 3) {
  margin-left: 69.5652173913%;
  margin-right: -100%;
  clear: none;
}

@media (max-width: 900px) {
  .product--image {
    width: 47.8260869565%;
    float: left;
  }
  .product--image:nth-child(2n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .product--image:nth-child(2n + 2) {
    margin-left: 52.1739130435%;
    margin-right: -100%;
    clear: none;
  }
}

@media (max-width: 800px) {
  .product--image {
    width: 21.7391304348%;
    float: left;
    padding-bottom: 14.2857142857%;
    margin-bottom: 4.347826087%;
  }
  .product--image:nth-child(4n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .product--image:nth-child(4n + 2) {
    margin-left: 26.0869565217%;
    margin-right: -100%;
    clear: none;
  }
  .product--image:nth-child(4n + 3) {
    margin-left: 52.1739130435%;
    margin-right: -100%;
    clear: none;
  }
  .product--image:nth-child(4n + 4) {
    margin-left: 78.2608695652%;
    margin-right: -100%;
    clear: none;
  }
}

@media (max-width: 500px) {
  .product--image {
    width: 30.4347826087%;
    float: left;
    padding-bottom: 23.8095238095%;
  }
  .product--image:nth-child(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .product--image:nth-child(3n + 2) {
    margin-left: 34.7826086957%;
    margin-right: -100%;
    clear: none;
  }
  .product--image:nth-child(3n + 3) {
    margin-left: 69.5652173913%;
    margin-right: -100%;
    clear: none;
  }
}

.product--title {
  margin-bottom: 20px;
}

.product--title span {
  margin-top: 10px;
  display: block;
  font-size: 70%;
  text-transform: capitalize;
  color: rgba(90, 100, 110, 0.6);
}

.product--info {
  width: 42.8571428571%;
  float: right;
  margin-right: 0;
}

@media (max-width: 900px) {
  .product--info {
    width: 52.380952381%;
    float: right;
    margin-right: 0;
  }
}

@media (max-width: 800px) {
  .product--info {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px;
  }
}

.product--info h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.product--code {
  color: rgba(90, 100, 110, 0.6);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.product--price {
  padding: 10px 0;
  border-top: 1px solid rgba(90, 100, 110, 0.2);
  border-bottom: 1px solid rgba(90, 100, 110, 0.2);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 40px;
}

.product--price--value {
  font-size: 30px;
}

.product--description {
  margin-bottom: 30px;
}

.product--description p {
  overflow: auto;
  margin: 10px 0;
}

.product--description strong {
  font-weight: 400;
  display: block;
  width: 120px;
  float: left;
}

.product--description span {
  display: block;
  margin-left: 120px;
}

.product--config {
  border-top: 1px solid rgba(90, 100, 110, 0.2);
  padding-top: 30px;
}

.product button {
  margin-top: 20px;
}

.product--variants {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.product--variants:after {
  content: " ";
  display: block;
  clear: both;
}

.product--preview {
  position: relative;
  width: 200px;
  height: 200px;
}

.product--preview--moss {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
}

.product--preview--frame {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.variant {
  width: 21.7391304348%;
  float: left;
  display: block;
  text-align: center;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.variant:nth-child(4n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.variant:nth-child(4n + 2) {
  margin-left: 26.0869565217%;
  margin-right: -100%;
  clear: none;
}

.variant:nth-child(4n + 3) {
  margin-left: 52.1739130435%;
  margin-right: -100%;
  clear: none;
}

.variant:nth-child(4n + 4) {
  margin-left: 78.2608695652%;
  margin-right: -100%;
  clear: none;
}

.variant--image {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.variant label {
  padding: 0;
}

.stock-message {
  max-width: 450px;
}

.select-field {
  font-size: 0;
}

.selectize-control {
  max-width: 450px;
}

.selectize-input {
  border-radius: 0;
  border: 1px solid rgba(90, 100, 110, 0.5);
  box-shadow: none;
  padding: 15px 0 15px 10px;
  height: 50px;
}

.selectize-input input {
  height: auto;
}

.selectize-dropdown {
  font-size: 14px;
  box-shadow: 0 1px 15px 5px rgba(0, 0, 0, 0.1);
}

.selectize-control.single .selectize-input.input-active {
  box-shadow: none;
}

.selectize-control.single .selectize-input.dropdown-active {
  box-shadow: none;
}

.selectize-control.single .selectize-input:after {
  border-top-color: #7fa924;
  border-width: 8px 8px 0 8px;
}

.selectize-control.single .selectize-input.dropdown-active:after {
  border-width: 0 8px 8px 8px;
  border-bottom-color: #7fa924;
}

.selectize-dropdown [data-selectable] {
  padding: 10px 0 10px 0;
}

.selectize-dropdown .optgroup-header {
  line-height: 40px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(90, 100, 110, 0.39);
  border-top: 1px solid #e5e5e5;
  padding: 0 0 0 15px;
  background: #f8f8f8;
}

.selectize-dropdown .optgroup .option {
  padding-left: 40px;
}

.select--images {
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.select--text {
  display: inline-block;
  margin-top: 1px;
  padding: 5px 15px 5px 20px;
  background: rgba(255, 255, 255, 0.6);
}

.frame-selected {
  display: block;
  margin: -15px 0 -15px -10px;
  padding: 15px 0 15px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.frame-selected--text {
  padding: 5px 15px 5px 10px;
  background: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 20000;
  visibility: hidden;
  transform: translate3d(0, -100%, 0);
  transition: visibility 0s .3s, transform .3s;
}

.mobile-menu.visible {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: visibility 0s, transform .3s;
}

.mobile-menu--header {
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;
}

.mobile-menu--close {
  font-size: 24px;
}

.mobile-menu--items {
  padding-left: 40px;
  list-style: none;
  margin-top: 20px;
}

.mobile-menu--items a {
  display: block;
  color: #5a646e;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 0;
}

.hero-section {
  background-image: url("/img/despre-hero.jpg");
  background-size: cover;
  background-position: center bottom;
  text-align: center;
  height: 400px;
  margin-top: 70px;
  padding-top: 40px;
}

@media (max-width: 700px) {
  .hero-section {
    background: none;
    height: auto;
  }
}

.long-description {
  margin: 0 auto;
  text-align: center;
  max-width: 900px;
}

.moss-color, .frame {
  text-align: center;
  font-size: 16px;
  display: inline-block;
  width: 16.6666%;
  margin: 30px 0;
  vertical-align: top;
  padding: 0 5px;
}

@media (max-width: 1100px) {
  .moss-color, .frame {
    margin: 2.7% 0;
  }
}

@media (max-width: 770px) {
  .moss-color, .frame {
    width: 25%;
  }
}

@media (max-width: 470px) {
  .moss-color, .frame {
    width: 33.3333%;
  }
}

.moss-color--image, .frame--image {
  display: inline-block;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: 50% bottom;
  background-size: 160%;
}

@media (max-width: 1100px) {
  .moss-color--image, .frame--image {
    width: 80%;
    height: 0;
    padding-bottom: 80%;
  }
}

.moss-color--gallery, .frame--gallery {
  text-align: center;
  font-size: 0;
  margin: 0;
}

.moss-color--name, .frame--name {
  margin-top: 10px;
}

@media (max-width: 700px) {
  .moss-color--name, .frame--name {
    font-size: 14px;
  }
}

.frame--image {
  background-position: center;
  background-size: cover;
}

.specification {
  width: 47.3684210526%;
  float: left;
  margin: 20px 0;
}

.specification:nth-child(2n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.specification:nth-child(2n + 2) {
  margin-left: 52.6315789474%;
  margin-right: -100%;
  clear: none;
}

@media (max-width: 700px) {
  .specification {
    width: 100%;
    float: left;
    float: none;
  }
  .specification:nth-child(1n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: none;
  }
}

.specification--icon {
  display: inline-block;
  font-size: 30px;
  vertical-align: middle;
  margin-right: 20px;
}

.specification--description {
  padding-left: 50px;
}

.specification--description p {
  margin-top: 10px;
  margin-bottom: 0;
}

.centered-long {
  text-align: center;
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 760px) {
  .centered-long {
    text-align: left;
  }
}

.social-links {
  font-size: 0;
  text-align: center;
  padding-bottom: 20px;
}

.social-links a {
  font-size: 30px;
  display: inline-block;
  margin: 0 10px;
  color: rgba(255, 255, 255, 0.9);
}

.contact .social-links {
  padding: 20px 0;
  margin: 40px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.contact .social-links a {
  font-size: 40px;
  color: #5a646e;
}

.cart-notification {
  position: fixed;
  background: #5a646e;
  z-index: 9000;
  color: white;
  right: 0;
  top: 110px;
  font-weight: 300;
  vertical-align: middle;
  padding: 7px 30px 12px;
}

@media (max-width: 800px) {
  .cart-notification {
    font-size: 0;
    padding: 10px 20px 12px 20px;
  }
}

@media (max-width: 1100px) {
  .cart-notification {
    top: 80px;
  }
}

.cart-notification.is-showing {
  transform: translateX(0);
}

.cart-notification--count {
  font-size: 32px;
  vertical-align: baseline;
  padding: 0 10px 0 30px;
}

@media (max-width: 800px) {
  .cart-notification--count {
    font-size: 20px;
    padding-left: 20px;
  }
}

.cart-notification .icon {
  font-size: 18px;
}

@media (max-width: 800px) {
  .cart-notification .icon {
    font-size: 14px;
  }
}

.cart table {
  margin-top: 40px;
}

.cart tr {
  position: relative;
}

@media (max-width: 640px) {
  .cart tr {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 640px) {
  .cart th {
    display: none;
  }
}

.cart th:first-child {
  width: 80px;
}

.cart th:nth-child(3) {
  padding-left: 40px;
}

.cart th:nth-child(4) {
  padding-left: 10px;
}

@media (max-width: 640px) {
  .cart td {
    display: inline-block;
    width: 100%;
    border-bottom: none;
    text-align: center;
  }
}

.cart td:nth-child(3) {
  padding-left: 40px;
}

@media (max-width: 640px) {
  .cart td:nth-child(3) {
    padding-left: 0;
    width: auto;
  }
}

.cart td:nth-child(4) {
  padding-left: 10px;
}

@media (max-width: 640px) {
  .cart td:nth-child(4) {
    padding-left: 0;
    width: auto;
  }
}

@media (max-width: 640px) {
  .cart tbody {
    text-align: center;
  }
}

.cart tbody td:nth-child(3)::before, .cart tbody td:nth-child(4)::before {
  content: "\ea6a";
  font-family: "icomoon";
  display: none;
  font-weight: 500;
  margin: 0 20px;
  color: rgba(90, 100, 110, 0.6);
}

@media (max-width: 640px) {
  .cart tbody td:nth-child(3)::before, .cart tbody td:nth-child(4)::before {
    display: inline-block;
  }
}

.cart tbody td:nth-child(3)::before {
  margin-left: 0;
}

@media (max-width: 640px) {
  .cart tbody td:last-child {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 640px) {
  .cart tfoot {
    text-align: center;
  }
}

.cart tfoot tr {
  border-bottom: none;
}

.cart tfoot td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cart tfoot td:first-child, .cart tfoot td:nth-child(2) {
  border-bottom: 0;
}

@media (max-width: 640px) {
  .cart tfoot td:first-child, .cart tfoot td:nth-child(2) {
    display: none;
  }
}

@media (max-width: 640px) {
  .cart tfoot td:nth-child(5) {
    display: none;
  }
}

.cart .button-group {
  margin-top: 20px;
}

@media (max-width: 640px) {
  .cart .button-group {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .cart .button-group .button, .cart .button-group button, .cart .button-group .button-secondary, .cart .button-group .button-back {
    width: 100%;
  }
}

.cart-item--cell-content {
  display: inline-block;
}

.cart-item--image {
  margin-right: 40px;
  position: relative;
  width: 80px;
  height: 80px;
}

@media (max-width: 640px) {
  .cart-item--image {
    margin-right: 0;
  }
}

.cart-item--moss {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cart-item--frame {
  width: 80px;
  height: 80px;
}

.cart-item--details h4 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.cart-item--dimensions {
  font-weight: 300;
}

.cart-item--price {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.cart-item--price .smaller {
  font-size: 16px;
}

.cart-item--quantity {
  width: 100px !important;
  height: 40px !important;
  display: inline-block !important;
  font-size: 24px !important;
}

.cart-item--quantity-form {
  position: relative;
}

.cart-item--quantity-form label {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  position: absolute;
  padding: 14px 0 0 0;
  right: 10px;
  top: 0;
  text-transform: uppercase;
}

.cart-item--quantity + .parsley-errors-list {
  position: absolute;
  left: 50%;
  top: 100%;
  margin-top: 15px;
  transform: translateX(-50%);
  background-color: #DC4906;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;
  min-width: 200px;
  display: none;
}

.cart-item--quantity + .parsley-errors-list:before {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid;
  border-color: transparent transparent #DC4906 transparent;
  margin: 0;
}

.cart-item--quantity + .parsley-errors-list.filled {
  display: block;
}

.cart-item--quantity + .parsley-errors-list li {
  margin: 0;
}

.cart-item--delete {
  color: rgba(90, 100, 110, 0.6);
  background: transparent;
  padding: 3px 0 0 5px;
  margin: 0 !important;
  cursor: pointer;
}

.cart-item--delete:hover {
  color: #ffa500;
}

.checkout--form {
  width: 42.8571428571%;
  float: left;
  margin-right: 4.7619047619%;
}

@media (max-width: 750px) {
  .checkout--form {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.checkout--form input, .checkout--form textarea {
  max-width: none;
}

.checkout--summary {
  width: 52.380952381%;
  float: right;
  margin-right: 0;
}

@media (max-width: 750px) {
  .checkout--summary {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 1100px) {
  .checkout--summary tr {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.checkout--summary tr:first-child {
  padding-bottom: 0;
}

.checkout--summary tr:nth-child(2) {
  padding-top: 0;
}

.checkout--summary th {
  padding-top: 18px;
  padding-bottom: 4px;
}

@media (max-width: 1100px) {
  .checkout--summary th {
    display: none;
  }
}

@media (max-width: 1100px) {
  .checkout--summary td {
    display: inline-block;
    width: 100%;
    border-bottom: none;
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .checkout--summary td:nth-child(3), .checkout--summary td:nth-child(4) {
    padding-left: 0;
    width: auto;
  }
}

@media (max-width: 1100px) {
  .checkout--summary tbody {
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .checkout--summary tbody td:nth-child(3)::before, .checkout--summary tbody td:nth-child(4)::before {
    display: inline-block;
  }
}

@media (max-width: 1100px) {
  .checkout--summary tbody td:last-child {
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 1100px) {
  .checkout--summary tfoot {
    text-align: center;
  }
}

@media (max-width: 1100px) {
  .checkout--summary tfoot tr:first-child {
    border-bottom: none;
  }
}

.checkout--summary tfoot tr:first-child td {
  border-bottom: none;
  padding-bottom: 0;
}

.checkout--summary tfoot tr:nth-child(2) td {
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 0;
}

.checkout--summary tfoot tr:nth-child(3) td {
  padding-top: 0;
}

@media (max-width: 1100px) {
  .checkout--summary tfoot td:first-child, .checkout--summary tfoot td:nth-child(2), .checkout--summary tfoot td:nth-child(5) {
    display: none;
  }
}

.checkout--billing-radios label {
  display: inline-block;
  margin-right: 20px;
}

.checkout .cart-item--image {
  margin-right: 20px;
}

.checkout .cart-item--cell-content img {
  width: 80px;
}

@media (max-width: 1100px) {
  .checkout .cart-item--cell-content img {
    margin-right: 0;
    width: 80px;
  }
}

.checkout .cart-item--details h4 {
  font-size: 16px;
}

.checkout .cart-item--dimensions br {
  display: block;
}

@media (max-width: 1100px) {
  .checkout .cart-item--price {
    font-size: 24px;
  }
}

.billing-person,
.billing-company,
.delivery-address {
  display: none;
}

.billing-person.visible,
.billing-company.visible,
.delivery-address.visible {
  display: block;
}

@media (max-width: 1100px) {
  .voucher--form {
    text-align: center;
  }
}

.voucher--form input {
  display: inline-block;
  max-width: 200px;
}

.voucher--form button {
  margin-top: 0;
}

.parsley-errors-list {
  list-style: none;
  font-style: italic;
  font-weight: 500;
  color: #DC4906;
  padding-left: 0;
}

.parsley-errors-list li {
  margin-top: 5px;
}

.parsley-error {
  -webkit-box-shadow: 0 0 10px 0 #DC4906;
  box-shadow: 0 0 10px 0 #DC4906;
  border-color: #DC4906 !important;
}

.notification {
  width: 100%;
  color: white;
  padding: 10px 15px;
  margin: 10px 0;
}

.notification p {
  margin: 0;
}

.notification--success {
  background-color: #7fa924;
}

.alert {
  background-color: #ECEFF1;
  border: 1px solid #B0BEC5;
  color: #78909C;
  padding: 10px 0;
  margin: 20px 0;
}

.alert-danger {
  color: white;
  background-color: #FF8A65;
  border: 1px solid #FF5722;
}

.alert h5 {
  padding-left: 60px;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 5px;
  text-transform: uppercase;
}

.custom--subtitle {
  margin-top: 40px;
}

.custom--gallery {
  margin-top: 30px;
}

.custom--item {
  width: 22.8571428571%;
  float: left;
  margin-bottom: 2.8571428571%;
}

.custom--item:nth-child(4n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}

.custom--item:nth-child(4n + 2) {
  margin-left: 25.7142857143%;
  margin-right: -100%;
  clear: none;
}

.custom--item:nth-child(4n + 3) {
  margin-left: 51.4285714286%;
  margin-right: -100%;
  clear: none;
}

.custom--item:nth-child(4n + 4) {
  margin-left: 77.1428571429%;
  margin-right: -100%;
  clear: none;
}

@media (max-width: 600px) {
  .custom--item {
    width: 31.4285714286%;
    float: left;
  }
  .custom--item:nth-child(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .custom--item:nth-child(3n + 2) {
    margin-left: 34.2857142857%;
    margin-right: -100%;
    clear: none;
  }
  .custom--item:nth-child(3n + 3) {
    margin-left: 68.5714285714%;
    margin-right: -100%;
    clear: none;
  }
}

@media (max-width: 460px) {
  .custom--item {
    width: 48.5714285714%;
    float: left;
  }
  .custom--item:nth-child(2n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .custom--item:nth-child(2n + 2) {
    margin-left: 51.4285714286%;
    margin-right: -100%;
    clear: none;
  }
}

.badge {
  position: absolute;
  top: 30px;
  left: -55px;
  width: 200px;
  line-height: 30px;
  text-align: center;
  transform: rotateZ(-45deg);
  font-weight: 400;
}

.badge-container {
  position: relative;
  overflow: hidden;
}

.badge-unavailable {
  background-color: #eee;
  color: #bbbbbb;
}

.badge-promo {
  background-color: #ffa500;
  color: white;
}

.badge-small {
  font-size: 12px;
  line-height: 25px;
  top: 30px;
  left: 30px;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  width: 200%;
}

.badge-smaller {
  font-size: 12px;
  line-height: 20px;
  top: 22px;
  left: 24px;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  width: 200%;
}

.status {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
}

.status-new {
  background: #7fa924;
}

.status-processing {
  background: #ffa500;
}

.status-finished {
  background: #5a646e;
}

.status-disabled {
  background: #5a646e;
  opacity: .3;
}

.status--form {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  margin-left: 10px;
}

.status-select {
  height: 40px;
}

.status-select .selectize-input {
  height: 40px;
  padding: 10px 0 10px 10px;
}

.status-select.selectize-control.single .selectize-input:after {
  border-top-color: white;
  border-width: 8px 8px 0 8px;
}

.status-select.selectize-control.single .selectize-input.dropdown-active:after {
  border-width: 0 8px 8px 8px;
  border-bottom-color: white;
}

.status--selected {
  display: block;
  margin: -10px 0 -10px -10px;
  padding: 10px 0 10px 10px;
  color: white;
  font-weight: 500;
  font-size: 16px;
}

.status--colors {
  color: white !important;
  font-weight: 400;
  font-size: 16px;
  padding-left: 10px !important;
}

.status--colors:hover {
  color: white;
  font-weight: 600;
}

.order--table {
  margin-top: 40px;
  margin-bottom: 40px;
}

.order--table p {
  margin: 0;
}

.order--table td, .order--table th {
  padding-left: 3px;
  padding-right: 3px;
}

.order--table td {
  border: none;
}

.order--multiple-items {
  border-bottom: none;
}

.order--multiple-items:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order--multiple-items + .order--single-item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.order--link {
  color: #5a646e;
  font-weight: 400;
  display: block;
}

.order--finished {
  opacity: .6;
}

.order--client-name {
  text-transform: uppercase;
  font-weight: 600;
}

.order--client-email {
  font-style: italic;
}

.order--client-phone {
  font-style: italic;
}

.order--variant-images {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
}

.order--variant-moss {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.order--product-details {
  display: inline-block;
  vertical-align: middle;
}

.order--product-name {
  font-weight: 600;
  text-transform: uppercase;
}

.order--numbers {
  font-size: 24px;
  font-weight: 600;
}

.order--numbers .icon {
  font-size: 16px;
  margin-right: 10px;
  color: rgba(90, 100, 110, 0.5);
}

.order--numbers .smaller {
  font-size: 16px;
  font-weight: 400;
}

.order--delete {
  padding: 0;
  background: transparent;
  color: rgba(90, 100, 110, 0.6);
}

.order--info {
  width: 42.8571428571%;
  float: left;
  margin-right: 4.7619047619%;
}

.order--products {
  width: 52.380952381%;
  float: right;
  margin-right: 0;
}

.order--products tfoot tr {
  border-bottom: none;
}

.order--products tfoot tr:first-child td {
  border: none;
  padding-bottom: 0;
}

.order--products tfoot tr:nth-child(2) td {
  padding-top: 0;
  border: none;
  padding-bottom: 0;
}

.order--products tfoot tr:nth-child(3) td {
  padding-top: 0;
}

.order--products tfoot tr:last-child td {
  border-bottom: none;
}

.order--products tfoot td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order--products tfoot td:first-child, .order--products tfoot td:nth-child(2) {
  border-bottom: 0;
}

.order--date {
  float: right;
  font-size: 18px;
  margin-top: 23px;
}

.order--row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin: 10px 0;
}

.order--row:after {
  content: " ";
  display: block;
  clear: both;
}

.order--field {
  width: 36.8421052632%;
  float: left;
  margin-right: 5.2631578947%;
}

.order--data {
  width: 57.8947368421%;
  float: right;
  margin-right: 0;
  font-weight: 600;
}

.order--products td, .order--products th {
  padding-left: 3px;
  padding-right: 3px;
}

.order--products th {
  padding-top: 18px;
  padding-bottom: 4px;
}

.order--action-buttons {
  display: inline-block;
  /*font-size: 20px;*/
}

.order--action-buttons .button, .order--action-buttons button, .order--action-buttons .button-secondary, .order--action-buttons .button-back {
  display: inline-block;
  padding: 0 20px;
  vertical-align: 4px;
}

.order-item--images {
  font-size: 0;
  position: relative;
  width: 50px;
  height: 50px;
}

.order-item--images .icon {
  font-size: 16px;
  color: #5a646e;
  opacity: .4;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.order-item--moss {
  width: 50px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.order-item--frame {
  vertical-align: middle;
  width: 50px;
  display: inline-block;
}

.order-item--name {
  margin: 0;
}

.filters {
  float: right;
}

.filter {
  color: #5a646e;
  font-weight: 300;
  margin-left: 20px;
  font-size: 16px;
}

.filter:before {
  vertical-align: middle;
  margin-right: 5px;
  display: inline-block;
  content: "";
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.filter-all:before {
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: white;
}

.filter-new:before {
  background: #7fa924;
}

.filter-processing:before {
  background: #ffa500;
}

.filter-finalized:before {
  background: #5a646e;
}

.pagination {
  list-style: none;
  font-size: 0;
  text-align: center;
  margin-top: 20px;
}

.pagination .disabled {
  visibility: hidden;
}

.pagination li {
  display: inline-block;
  font-size: 24px;
  padding: 5px 10px;
}

.announcement {
  text-align: center;
  margin-bottom: 20px;
  color: #ffa500;
}

.announcement p {
  font-style: italic;
  margin: 0;
  font-size: 14px;
}

.form--button-group {
  font-size: 0;
  text-align: right;
  margin: 30px 0;
}

.form--button-group > * {
  margin-left: 30px;
}

.input--short {
  max-width: 180px;
  width: 100%;
  position: relative;
}

.input--dimensions {
  border: 1px solid rgba(90, 100, 110, 0.5);
  max-width: 450px;
  width: 100%;
  position: relative;
  background: white;
}

.input--dimensions input {
  border: none;
  display: inline-block;
  width: 55px;
  background: none;
}

.input--unit {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: .4;
}

.input--image input[type="file"] {
  display: none;
}

.input--image input[type="file"] + label {
  margin-top: 18px;
}

.select--moss--option {
  height: 50px;
  position: relative;
  border-top: 1px solid #e5e5e5;
  overflow: hidden;
}

.select--moss--option.active {
  background: white;
}

.select--moss--option.active .select--moss--text {
  font-weight: 600;
}

.select--moss--image {
  position: absolute;
  right: -5px;
  top: 0;
  height: 100px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotateZ(-7deg);
  z-index: 50;
}

.select--moss--text {
  position: relative;
  z-index: 100;
  display: inline-block;
  margin-top: 1px;
  padding: 5px 15px 5px 15px;
  font-weight: 400;
  background: rgba(255, 255, 255, 0.6);
}

.select--moss--stock {
  color: coral;
  font-style: italic;
}

.select--moss--selected {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  padding: 15px 0 15px 0;
}

.select--moss--selected--image {
  position: absolute;
  right: -5px;
  top: 0;
  height: 100px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotateZ(-7deg);
  z-index: 50;
}

.select--moss--selected--text {
  padding: 5px 15px 5px 10px;
  background: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 400;
  position: relative;
  z-index: 100;
}
