//Typography
$font-base: 'Raleway', sans-serif;
$font-size: 16px;

//Colors
$color-highlight: #ffa500;
$color-accent: #7fa924;
$color-primary: #5a646e;
$color-primary-dark: #3f454c;
$color-parsley-error: #DC4906;
$color-parsley-success: #7fa924;

//Susy grid
$susy: (
        columns: 11,
        gutters: 1,
        debug: (
          // image: show
        )
);