.form {
    &--button-group {
        font-size: 0;
        text-align: right;
        margin: 30px 0;

        > * {
            margin-left: 30px;
        }
    }
}
.input {

    &--short {
        max-width: 180px;
        width: 100%;
        position: relative;
        
    }
    &--dimensions {
        border: 1px solid rgba(90, 100, 110, 0.5);
        max-width: 450px;
        width: 100%;
        // padding-left: 10px;
        position: relative;
        background: white;

        input {
            border: none;
            display: inline-block;
            width: 55px;
            background: none;
            // padding-left: 0;
        }
    }

    &--unit {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        // font-weight: 600;
        opacity: .4;
    }

    &--image {
        input[type="file"] {
            display: none;

            & + label {
                margin-top: 18px;
            }
        }
        
    }
}

.select {
    &--moss {
        &--option {
            height: 50px;
            position: relative;
            border-top: 1px solid #e5e5e5;
            overflow: hidden;

            &.active {
                background: white;
                .select--moss--text {
                    font-weight: 600;
                }
            }
        }
        &--image {
            position: absolute;
            right: -5px;
            top: 0;
            height: 100px;
            width: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            transform: rotateZ(-7deg);
            z-index: 50;
        }
        &--text {
            position: relative;
            z-index: 100;
            display: inline-block;
            margin-top: 1px;
            // margin-left: 120px;
            padding: 5px 15px 5px 15px;
            font-weight: 400;
            
            background: rgba(255, 255, 255, .6);
        }
        &--stock {
            color: coral;
            font-style: italic;
        }
        &--selected {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            // margin: -10px 0 -15px -10px;
            padding: 15px 0 15px 0;
            
            &--image {
                position: absolute;
                right: -5px;
                top: 0;
                height: 100px;
                width: 200px;
                background-repeat: no-repeat;
                background-size: cover;
                transform: rotateZ(-7deg);
                z-index: 50;
            }
          
            &--text {
              padding: 5px 15px 5px 10px;
              background: rgba(255, 255, 255, .6);
              font-size: 14px;
              font-weight: 400;
              position: relative;
              z-index: 100;
            //   margin-left: 120px;

            // padding: 5px 15px 5px 15px;
              //font-weight: 400;
            }
        }
    }
}
