.button {
  display: inline-block;
  background-color: $color-primary;
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 50px;
  vertical-align: middle;
  padding: 0 30px;
  font-size: 16px;
  cursor: pointer;


}

button {
  @extend .button;
}
button:disabled {
  opacity: .3;
  cursor: not-allowed;
}

.button-secondary {
  @extend .button;
  color: #8d97a1;
  background: transparent;
}
.button-back {
  @extend .button;
  color: #8d97a1;
  background: #eeeeee;
}
.button-small {

}
.button-group {
  form {
    display: inline-block;
  }
}

.form-button {
  display: inline-block;
}
.action-button {
  opacity: .6;
  padding: 0 10px;
  color: $color-primary;
  background: none;
}

.loading-icon {
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: relative;
  display: inline-block;
}

@keyframes loading {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(-360deg);
  }
}