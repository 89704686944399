.footer {
  background-color: $color-primary-dark;
  padding: 30px 0 30px 0;
  color: white;

  h5 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    opacity: .7;
    margin-bottom: 14px;
  }

  &--contact {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
    line-height: 1.8;
    
  }

  &--logo {
    text-align: center;
    margin: 50px 0 30px;

    img {
      max-width: 200px;
      opacity: .5;
    }
  }
  
  &--utils {
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
    h5 {
      margin: -10px 0 5px;
    }
    a {
      margin: 0 10px;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      padding-bottom: 2px;
      
      &:hover {
        border-bottom-color: rgba(255, 255, 255, 1);
      }
    }
  }

  &--copyright {
    font-weight: 300;
    opacity: .7;
    text-align: center;
    font-size: 14px;

    p {
      margin: 0;
    }
  }
}