.checkout {

  &--form {
    @include span(5 of 11);

    @media (max-width: 750px) {
      @include span(full);
    }

    input, textarea {
      max-width: none;
    }
  }

  &--summary {
    @include span(6 of 11 last);

    @media (max-width: 750px) {
      @include span(full);
    }

    tr {
      
      @media (max-width: 1100px) {
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        //padding-top: 20px;
        //padding-bottom: 20px;
      }

      &:first-child {
        padding-bottom: 0;
      }
      &:nth-child(2) {
        padding-top: 0;
      }
    }

    th {
      padding-top: 18px;
      padding-bottom: 4px;

      @media (max-width: 1100px) {
        display: none;
      }
    }

    td {

      @media (max-width: 1100px) {
        display: inline-block;
        width: 100%;
        border-bottom: none;
        text-align: center;
      }

      &:nth-child(3),
      &:nth-child(4) {
        @media (max-width: 1100px) {
          padding-left: 0;
          width: auto;
        }
      }

    }

    tbody {
      @media (max-width: 1100px) {
        text-align: center;
      }

      td {
        &:nth-child(3),
        &:nth-child(4) {

          &::before {
            @media (max-width: 1100px) {
              display: inline-block;
            }

          }
        }

        &:last-child {

          @media (max-width: 1100px) {
            width: auto;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

    }



    tfoot {
      @media (max-width: 1100px) {
        text-align: center;
      }

      tr {
        &:first-child {
          @media (max-width: 1100px) {
            border-bottom: none;
          }
          td {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        &:nth-child(2) {
          td {
            border-bottom: none;
            padding-bottom: 0;
            padding-top: 0;
          }
        }
        &:nth-child(3) {
          td {
            padding-top: 0;
          }
        }
      }

      td {

        &:first-child,
        &:nth-child(2),
        &:nth-child(5) {
          @media (max-width: 1100px) {
            display: none;
          }
        }

      }

    }
  }

  &--billing-radios {
    label {
      display: inline-block;
      margin-right: 20px;

    }
  }

  .cart-item{
    &--image {
      margin-right: 20px;
    }
    &--cell-content{
      img {
        width: 80px;
        // margin-right: 20px;

        @media (max-width: 1100px) {
          margin-right: 0;
          width: 80px;
        }

      }
    }

    &--details {
      h4 {
        font-size: 16px;
      }
    }

    &--quantity {
    }

    &--dimensions {

      br {
        display: block;
      }
    }

    &--price {
      @media (max-width: 1100px) {
        font-size: 24px;
      }
    }

  }
}

.billing-person,
.billing-company,
.delivery-address {

    display: none;

  &.visible {
    display: block;
  }
}


.voucher--form {
  @media (max-width: 1100px) {
    text-align: center;
  }
  input {
    display: inline-block;
    max-width: 200px;
  }
  button {
    margin-top: 0;
  }
}
